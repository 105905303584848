import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import styled from '@emotion/styled';

import {
  BreadcrumbProps,
  ImageProgressbar,
  MenuItem,
  packageCartStoreContext,
  useAuth,
  useTranslation,
  Error
} from '@ibe/components';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import fallback from '../../Translations/generated/Header.en.json';
import Keys from '../../Translations/generated/Header.en.json.keys';
// eslint-disable-next-line import/no-cycle
import { b2bPages, manageBookingPages, PageUrl } from '../../pages';
import SummaryPopoverWithActions from '../../Components/SummaryPopoverWithActions/SummaryPopoverWithActions';
import { useMount } from 'react-use';
import { LocalStorageKey } from '../../Storage/LocalStorageKeyRegistry';
import PageUtil from '../../Util/PageUtil';
import ManageBookingLink from '../../Components/ManageBookingLink/ManageBookingLink';
import { ApiIncludedService, ApiPackageModel, ApiRole } from '@ibe/api';
import AgencyMenu from '../../Components/AgencyMenu/AgencyMenu';
import WorkflowTabs from '../../Components/WorkflowTabs/WorkflowTabs';
import useCms from '../../Hooks/useCms';
import useAuthSessionPersistence from '../../Hooks/useAuthSessionPersistence';

const HeaderContainer = styled.div`
  margin: auto;
  padding: 1rem 15px;
`;

const Header = observer(
  ({
    breadcrumbProps,
    onGoToCheckout,
    editBookingItemExtension,
    packageIncludedServices,
    packageModel
  }: {
    breadcrumbProps?: BreadcrumbProps;
    onGoToCheckout?: () => Promise<void>;
    editBookingItemExtension?: (itemIDs: Array<string>) => void;
    packageIncludedServices?: Array<ApiIncludedService>;
    packageModel?: ApiPackageModel;
  }) => {
    const { t } = useTranslation('Header', fallback);
    const { authState } = useAuth();
    const history = useHistory();
    const location = useLocation<{ noNavigationBackModal: boolean }>();
    const [showNavigationBackModal, setShowNavigationBackModal] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [showWorkflowTabs, setShowWorkflowTabs] = useState<boolean>(false);
    const [prevPagePath] = useAuthSessionPersistence<string>(LocalStorageKey.PAGE_URL);
    const [isCartDisplayed, setIsCartDisplayed] = useState<boolean>(false);
    const packageCartContext = useContext(packageCartStoreContext);
    const packageCartStore = useLocalStore(() => packageCartContext);
    const cms = useCms();

    function onErrorAction() {
      setShowErrorModal(true);
    }

    useMount(() => {
      const currentPath = location.pathname;
      const noNavigationBackModal =
        location.state?.noNavigationBackModal ||
        manageBookingPages.includes(currentPath as PageUrl);

      if (
        !noNavigationBackModal &&
        PageUtil.getShoppingCartPagePaths().indexOf(currentPath) === -1 &&
        PageUtil.getShoppingCartPagePaths().some(value => value === prevPagePath) &&
        packageCartStore.cart
      ) {
        setShowNavigationBackModal(true);
      }
    });

    useEffect((): void => {
      if (manageBookingPages.includes(location.pathname as PageUrl)) {
        setShowWorkflowTabs(false);
      } else {
        setShowWorkflowTabs(
          cms.workflows.length > 1 || b2bPages.includes(location.pathname as PageUrl)
        );
      }
    }, [cms.workflows, location]);

    function shouldShowShoppingCart(): boolean {
      return ![
        ...manageBookingPages,
        PageUrl.CHECKOUT,
        PageUrl.HOTEL_LIST,
        PageUrl.CONFIRMATION
      ].includes(location.pathname as PageUrl);
    }

    function shouldShowLoggedInButton(): boolean {
      return !!authState.user && !authState.isGuest && !authState.agency?.number;
    }

    const UserMenuLoggedIn = (): JSX.Element => (
      <>
        <Button
          className="user-logged-in-button"
          color="primary"
          onClick={(): void => {
            history.push(PageUrl.LOGOUT);
          }}
        >
          <div className="mx-2 px-2">
            {authState.user &&
              `${authState.user.firstName} ${authState.user.lastName} | ${t(Keys.Logout)}`}
          </div>
        </Button>
      </>
    );

    return (
      <HeaderContainer>
        {showWorkflowTabs && <WorkflowTabs />}
        <div className="d-flex align-items-center mb-3 justify-content-end">
          {authState.isAgency &&
          (authState.role === ApiRole.AGENT || authState.role === ApiRole.AGENCYOWNER) ? (
            <AgencyMenu
              userName={
                authState.user ? `${authState.user.firstName} ${authState.user.lastName}` : ''
              }
              displayDivider={location.pathname !== PageUrl.CHECKOUT && isCartDisplayed}
            />
          ) : (
            <ManageBookingLink
              displayDivider={location.pathname !== PageUrl.CHECKOUT && isCartDisplayed}
            />
          )}
          <div className="d-flex align-items-center">
            {shouldShowLoggedInButton() && <UserMenuLoggedIn />}
            {shouldShowShoppingCart() && (
              <SummaryPopoverWithActions
                header={sessionStorage.getItem('PACKAGE_NAME') || undefined}
                onGoToCheckout={onGoToCheckout}
                editBookingItemExtension={editBookingItemExtension}
                onBookingChange={(bookingExist: boolean) => setIsCartDisplayed(bookingExist)}
                packageIncludedServices={packageIncludedServices}
                onErrorAction={onErrorAction}
                packageModel={packageModel}
              />
            )}
          </div>
        </div>
        {!!breadcrumbProps && (
          <ImageProgressbar
            steps={breadcrumbProps.items.map((step: MenuItem, idx: number) => ({
              description: step.label,
              image: faArrowRight,
              selected: idx === breadcrumbProps.activeIndex
            }))}
            showNotActiveIcon
            onClick={(stepIndex: number, description: string) => {
              breadcrumbProps.onClickAction(
                // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                breadcrumbProps.items.find(item => item.label === description)!,
                stepIndex
              );
            }}
          />
        )}
        <Modal
          className="header_navigationBack-modal"
          isOpen={showNavigationBackModal}
          backdrop={true}
          toggle={() => {
            setShowNavigationBackModal(!showNavigationBackModal);
            packageCartStore.resetCart();
          }}
          container={document.getElementById('iso') || undefined}
        >
          <ModalHeader>{t(Keys.navigationBackModal.header)}</ModalHeader>
          <ModalBody id="header_navigationBack-modal">
            {t(Keys.navigationBackModal.content)}
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col xs={12} md={'auto'} className="mb-2">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={(): void => {
                    setShowNavigationBackModal(false);
                    packageCartStore.resetCart();
                  }}
                >
                  {t(Keys.navigationBackModal.back)}
                </button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>

        <Modal
          className="hotelDetails-modal"
          isOpen={showErrorModal}
          backdrop={true}
          container={document.getElementById('iso') || undefined}
        >
          <ModalBody id={'hotelDetails-modal'}>
            <Row>
              <Col>
                <Row className="justify-content-center">
                  <div className="text-center px-2 py-1">
                    <Error message={t(Keys.errorModalInformation)} />
                  </div>
                </Row>
                <Row className="justify-content-center">
                  <Button
                    className="btn-primary"
                    onClick={(): void => {
                      setShowErrorModal(false);
                    }}
                  >
                    <div className="text-center px-2 py-1">{t(Keys.errorModalButtonLabel)}</div>
                  </Button>
                </Row>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </HeaderContainer>
    );
  }
);

export default Header;
