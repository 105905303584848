/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-08-01 08:48:35 */

const Keys = {
  emptyCart: 'emptyCart',
  total: 'total',
  subTotal: 'subTotal',
  taxes: 'taxes',
  discounts: 'discounts',
  voucher: 'voucher',
  priceDetails: 'priceDetails',
  close: 'close',
  seats: 'seats',
  numberOfPerson: 'numberOfPerson',
  numberOfPerson_plural: 'numberOfPerson_plural',
  adult: 'adult',
  adult_plural: 'adult_plural',
  child: 'child',
  child_plural: 'child_plural',
  infant: 'infant',
  infant_plural: 'infant_plural',
  time: 'time',
  nightsAt: 'nightsAt',
  nightsAt_plural: 'nightsAt_plural',
  room: 'room'
}; export default Keys;