import { ConfigModel } from '@ibe/services';

class MegConfigModel extends ConfigModel {
  hideSpecialRequests?: boolean;

  disablePackageNavigation?: boolean;

  hideMoveTicketOnHotelList?: boolean;

  disableBookingOverviewAddressChange?: boolean;

  paymentStyleUrl?: string;

  hotelListResultsQuantity?: number;

  hideOccupancyInRoomsItem?: boolean;

  cachePackageComponentsRequests?: boolean;

  hidePaymentInformation?: boolean;

  showTravelPlan?: boolean;

  multipleTimeSlots?: boolean;
}

export default MegConfigModel;
