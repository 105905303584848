import { useMemo } from 'react';
import { ApiBooking, ApiHotel, ApiIncludedService, ApiItemType } from '@ibe/api';
import { getServiceDescriptions, IncludedServicesUtil } from '@ibe/components';

const useIncludedServices = (
  booking?: ApiBooking,
  packageIncludedServices?: ApiIncludedService[]
) => {
  const includedServices = useMemo(() => {
    const hotelModel = booking?.items?.find(
      item => item.itemType === ApiItemType.HOTEL
    ) as ApiHotel;
    return {
      includedServices: hotelModel?.includedServices || [],
      start: hotelModel?.start,
      end: hotelModel?.end
    };
  }, [booking]);

  const { servicesString, servicesDescriptions } = getServiceDescriptions(
    IncludedServicesUtil.getIncludedServices(
      includedServices.includedServices,
      includedServices.start,
      booking?.salesChannel
    ),
    IncludedServicesUtil.getIncludedServices(packageIncludedServices, includedServices.start)
  );

  return { servicesString, servicesDescriptions };
};

export default useIncludedServices;
