import React from 'react';

import { ApiInsuranceUnit } from '@ibe/api';
import { sanitize } from '@ibe/services';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenAlt } from '@fortawesome/free-solid-svg-icons';
import { BookedItemComponentProps, Dates, Price } from '@ibe/components';

const CustomInsuranceCartView = (props: BookedItemComponentProps): JSX.Element => {
  const { item, booking, onEdit, isInEditMode } = props;

  const price = item.price || null;

  const insuranceUnit = booking.items.find(it => item.idParent === it.id) as ApiInsuranceUnit;

  return (
    <Row>
      <Col>
        <Row className="align-items-center">
          <Col>
            <h6 className="my-0">
              {insuranceUnit && insuranceUnit.name ? insuranceUnit.name : item.name}
            </h6>
          </Col>
          <Col xs={'auto'} className="d-flex justify-content-end align-items-end">
            {onEdit && isInEditMode && (
              <Button
                outline
                color="secondary btn-sm"
                onClick={(): void => {
                  onEdit(item.id);
                }}
              >
                <FontAwesomeIcon icon={faPenAlt} />
              </Button>
            )}
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            {insuranceUnit && insuranceUnit.description && (
              <div dangerouslySetInnerHTML={{ __html: sanitize(insuranceUnit.description) }} />
            )}
          </Col>
        </Row>
        <Row className="p-0">
          <Col className="d-flex py-0">
            <Dates item={item} />
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            <span className="text-muted ml-1">{price && <Price price={price} />}</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CustomInsuranceCartView;
