import React, { useEffect, useState, useLayoutEffect } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';

import { SessionTimeOutModal, useAppService, useAuth, useConfig } from '@ibe/components';
import i18next from 'i18next';
import ProtectedRoute from './Components/ProtectedRoute';
import {
  extrasWorkflowPages,
  getPageByUrl,
  pages,
  PageUrl,
  singleHotelBookingPages
} from './pages';
import NotFound from './Pages/NotFound';
import RouteBroadcast from './RouteBroadcast';
import { useMount } from 'react-use';
import useQuery from './Util/useQuery';
import { QueryUtils, SessionStorageKeys } from './Util/QueryUtils';
import useCms from './Hooks/useCms';
import { LoggerFactory } from '@ibe/services';
import { LocationSnapshot } from './Layouts/BlankPageLayout';
import PageUtil from './Util/PageUtil';
import { WorkflowType } from './Models/WorkflowType';
import { initLanguage } from './Config/config';
import { Element as ScrollTo } from 'react-scroll';

interface Props {
  mode?: 'DEFAULT' | 'WIDGET';
  defaultUrl?: string;
}

const logger = LoggerFactory.get('App');

const App = observer(({ mode, defaultUrl }: Props) => {
  const appService = useAppService();
  const config = useConfig();
  const lang = initLanguage(i18next.language, config.defaultLanguage);
  appService.setLang(lang);
  appService.setCurrency(config.defaultCurrency);
  const auth = useAuth();
  const location = useLocation();
  const { pathname, search } = location;
  const history = useHistory();
  const query = useQuery();
  const workflow = QueryUtils.getWorkflowCode(query);
  const cms = useCms();
  const [sessionTimeOutNotificationOffset] = useState(
    config.session.expirationTimeNotificationOffset
  );

  const [guestExpirationTimeNotificationOffset] = useState(
    config.session.guestExpirationTimeNotificationOffset
  );

  const [sessionExpirationTimeOut, setSessionExpirationTimeOut] = useState(
    auth.authService.getTokenExpiration() || 0
  );

  useEffect(() => {
    setSessionExpirationTimeOut(auth.authService.getTokenExpiration() || 0);
    document.body.setAttribute('data-ibe-url', location.pathname + location.search);
  }, [auth.authService, location, auth.authState.token]);

  useLayoutEffect(() => {
    // ============================================================================================
    const sessionStorageValue = sessionStorage.getItem('NAVIGATION');
    const currentValue: LocationSnapshot[] = sessionStorageValue
      ? JSON.parse(sessionStorageValue)
      : [];
    currentValue.push({ pathname: pathname, search: search });
    sessionStorage.setItem(SessionStorageKeys.NAVIGATION, JSON.stringify(currentValue));
    // ============================================================================================
  }, [location]);

  useEffect(() => {
    if (workflow) {
      cms.fetchWorkflows(workflow).then(workflows => {
        if (
          !extrasWorkflowPages.includes(location.pathname as PageUrl) ||
          !singleHotelBookingPages.includes(location.pathname as PageUrl)
        ) {
          const relevantWorkflow = workflows.find(
            item => item.type === WorkflowType.PackageWorkflow
          );
          const isB2BWorkflow = relevantWorkflow && relevantWorkflow.b2bWorkflow;
          if (isB2BWorkflow && !auth.authState.agency?.number) {
            history.push(`${PageUrl.LOGIN}?wrk=${workflow}`);
          }
        }
      });
    }
  }, [workflow]);

  useMount(() => {
    if (defaultUrl) {
      history.push(defaultUrl);
    }
  });

  function getSessionModal(): JSX.Element | undefined {
    if (auth.authState.token && sessionExpirationTimeOut) {
      if (auth.authService.isLoggedIn) {
        return (
          <SessionTimeOutModal
            sessionTimeOut={sessionExpirationTimeOut}
            sessionTimeOutNotificationOffset={sessionTimeOutNotificationOffset * 60 * 1000}
            onLogoutRedirection={() =>
              history.push(
                PageUtil.getWorkflowEntryPoint(
                  {
                    pathname: location.pathname,
                    search: location.search
                  },
                  true
                )
              )
            }
            silentRefreshThrottle={10_000}
          />
        );
      } else if (guestExpirationTimeNotificationOffset) {
        return (
          <SessionTimeOutModal
            sessionTimeOut={sessionExpirationTimeOut}
            sessionTimeOutNotificationOffset={guestExpirationTimeNotificationOffset * 60 * 1000}
            onLogoutRedirection={() =>
              history.push(
                PageUtil.getWorkflowEntryPoint(
                  {
                    pathname: location.pathname,
                    search: location.search
                  },
                  false
                )
              )
            }
            silentRefreshThrottle={10_000}
            allowUnauthorizedUser
          />
        );
      }
    }
  }

  return (
    <>
      <ScrollTo name={'root'}>
        <Switch>
          {Object.keys(pages).map(url => {
            const page = getPageByUrl(url);
            const routeProps = {
              exact: url === '/',
              path: url,
              key: url,
              children: page.component
            };
            return page.requiredRoles && page.requiredRoles.length > 0 ? (
              <ProtectedRoute
                requiredRoles={page.requiredRoles}
                redirectTo={PageUrl.LOGIN}
                exact={routeProps.exact}
                path={routeProps.path}
                key={routeProps.key}
              >
                {page.component}
              </ProtectedRoute>
            ) : (
              <Route exact={routeProps.exact} path={routeProps.path} key={routeProps.key}>
                {page.component}
              </Route>
            );
          })}
          <Route path="*" key="404">
            <NotFound />
          </Route>
        </Switch>
        <RouteBroadcast mode={mode} />
        {getSessionModal()}
      </ScrollTo>
    </>
  );
});

export default App;
