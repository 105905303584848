import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  ApiBookedItem,
  ApiIncludedService,
  ApiItemType,
  ApiPackageModel,
  ApiServiceSelectionType
} from '@ibe/api';
import { observer, useLocalObservable } from 'mobx-react';
import {
  CustomItemRegistry,
  getBookedItemsList,
  LoadingOverlay,
  MEDIAQUERY_DEFAULTS,
  Overlay,
  packageCartStoreContext,
  ServiceSearchDetailParams,
  SummaryPopover,
  SummaryPopoverActions,
  useApi,
  useBookingService,
  useMediaQuery,
  usePersistSearchParams,
  useTranslation
} from '@ibe/components';
import { cartEditItemEvent, cartRemoveItemEvent } from '@ibe/services';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Keys from '../../Translations/generated/SummaryPopoverWithActions.en.json.keys';
import { PageUrl } from '../../pages';
import fallback from '../../Translations/generated/SummaryPopoverWithActions.en.json';
import fallbackItems from '../../Translations/generated/Items.en.json';
import summaryFallback from '../../Translations/generated/summary.en.t.json';
import bookedItemComponentFallback from '../../Translations/generated/booked-item-component.en.t.json';
import { useHistory } from 'react-router';
import CartUtil from '../../Util/CartUtil';
import BookingUtils from '../../Util/BookingUtils';
import useQuery from '../../Util/useQuery';
import { bookingItemRemovedEvent } from '../../Tracking/Events/Events';
import useIncludedServices from '../../Hooks/useIncludedServices';
import { useLocation } from 'react-router-dom';
import { QueryUtils } from '../../Util/QueryUtils';
import CustomHotelCartView from '../Items/Hotel/Cart';
import CustomExtraCartView from '../Items/Extra/Cart';
import { configService } from '../../Config/config';
import PageUtil from '../../Util/PageUtil';
import { faCheck, faPenAlt } from '@fortawesome/free-solid-svg-icons';
import CustomInsuranceCartView from '../Items/Insurance/Cart';

const customItemRegistry: CustomItemRegistry = {
  [ApiItemType.HOTEL]: { Cart: CustomHotelCartView },
  [ApiItemType.EXTRA]: { Cart: CustomExtraCartView },
  [ApiItemType.INSURANCE]: { Cart: CustomInsuranceCartView }
};

interface SummaryPopoverWithActionsProps {
  header?: string;
  onGoToCheckout?: () => Promise<void>;
  editBookingItemExtension?: (itemIDs: Array<string>) => void;
  isLoggedIn?: boolean;
  onBookingChange?: (bookingExist: boolean) => void;
  packageIncludedServices?: Array<ApiIncludedService>;
  packageModel?: ApiPackageModel;
  customSummaryPopoverActions?: SummaryPopoverActions;
  workflowType?: string;
  onErrorAction?: () => void;
}

const SummaryPopoverWithActions = observer(function SummaryPopoverWithActions(
  props: SummaryPopoverWithActionsProps
): JSX.Element {
  const {
    header,
    onGoToCheckout,
    editBookingItemExtension,
    isLoggedIn,
    onBookingChange,
    packageIncludedServices,
    workflowType,
    onErrorAction,
    packageModel
  } = props;

  const bookingService = useBookingService();
  const { t } = useTranslation('SummaryPopoverWithActions', fallback);

  // force app to preload translations to avoid flickering
  useTranslation('summary', summaryFallback);
  useTranslation('booked-item-component', bookedItemComponentFallback);
  useTranslation('Items', fallbackItems);

  const history = useHistory();
  const location = useLocation();
  const matches = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemIDsToRemove, seItemIDsToRemove] = useState<Array<string>>();
  const [itemIDsToEdit, seItemIDsToEdit] = useState<Array<string>>();
  const [showRemovalModal, setShowRemovalModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);

  const { getSearchParams } = usePersistSearchParams<Partial<ServiceSearchDetailParams>>();

  const api = useApi();
  const query = useQuery();
  const packageCartContext = useContext(packageCartStoreContext);
  const packageCartStore = useLocalObservable(() => packageCartContext);
  packageCartStore.setApi(api);

  const onSingleHotelWorkflow = 'HotelWorkflow' === workflowType;
  const onExtrasListPage = location.pathname === PageUrl.EXTRAS_LIST_PAGE;

  const isRebookingWorkflow = QueryUtils.getIsRebookingWorkflow(query);
  const isAddExtraWorkflow = QueryUtils.getIsAddExtraWorkflow(query);
  const isExtraWorkflow = !!QueryUtils.getExtraTypeCode(query);
  const disablePackageNavigation = configService.get().disablePackageNavigation;

  const relatedExtraBookedItems = useMemo(() => {
    return CartUtil.findRelatedExtraBookedItems(
      packageCartStore.cart,
      bookingService.booking,
      !!isAddExtraWorkflow
    );
  }, [packageCartStore.cart, bookingService.booking]);

  function buildHeader(): JSX.Element {
    return (
      <Row className="w-100 no-gutters">
        <Col className="font-weight-bold">{header}</Col>
        <Col className="text-nowrap d-flex justify-content-end pr-0">
          {isInEditMode ? (
            <Button
              outline
              color="primary btn-sm"
              onClick={(): void => {
                setIsInEditMode(!isInEditMode);
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          ) : (
            <Button
              outline
              color="primary btn-sm"
              onClick={(): void => {
                setIsInEditMode(!isInEditMode);
              }}
            >
              <span className="mr-1">{t(Keys.edit)}</span>
              <FontAwesomeIcon icon={faPenAlt} />
            </Button>
          )}
        </Col>
      </Row>
    );
  }

  const getBooking = () => {
    if (onExtrasListPage) {
      return bookingService.booking ? bookingService.booking : undefined;
    } else {
      return BookingUtils.getCartBooking(
        packageCartStore.cart,
        bookingService.booking,
        relatedExtraBookedItems,
        onSingleHotelWorkflow,
        isAddExtraWorkflow
      );
    }
  };

  const booking = useMemo(getBooking, [
    packageCartStore?.cart?.packageModel.packageDetails?.[0],
    bookingService.booking,
    bookingService.booking?.id
  ]);

  useEffect(() => {
    if (onBookingChange) {
      onBookingChange(!!booking);
    }
  }, [booking]);

  const { servicesDescriptions, servicesString } = useIncludedServices(
    booking,
    packageIncludedServices
  );

  async function executeItemRemoval(bookedItemIDs: Array<string>): Promise<void> {
    const itemIDs: string[] = [];
    if (bookingService.booking) {
      bookingService.booking.bookedItems.forEach((value: ApiBookedItem) => {
        if (bookedItemIDs.includes(value.id)) {
          itemIDs.push(value.idParent);
        }
      });
    }

    const packageCartId = packageCartStore.cart?.id;

    if (packageCartId && !onSingleHotelWorkflow) {
      const removableItems = CartUtil.getRemovableItems(packageCartStore.cart, itemIDs);

      for (const [key, value] of removableItems.entries()) {
        await packageCartStore.packageCartRemoveItems(packageCartId, key.id, value);
        cartRemoveItemEvent.broadcast({ component: key });
      }
      if (isAddExtraWorkflow) {
        await packageCartStore.packageCartUpdateBooking(packageCartStore.cart?.id || '');
      } else {
        await packageCartStore.packageAttemptBooking();
      }
      await bookingService.init(packageCartStore.cart?.bookingId);
    }

    if (
      !packageCartId &&
      bookingService.booking &&
      bookingService.booking.id &&
      onSingleHotelWorkflow
    ) {
      await bookingService.init(bookingService.booking.id);
    }

    if (!packageCartId && bookingService.booking && bookingService.booking.id && onExtrasListPage) {
      if (itemIDs && itemIDs.length > 0) {
        await bookingService.removeItems(bookedItemIDs);
        cartRemoveItemEvent.broadcast({ component: null });
      }
    }

    return Promise.resolve();
  }

  async function removeItemFromBooking(itemIDs: Array<string> | string): Promise<void> {
    const idList: string[] = [];
    Array.isArray(itemIDs) ? idList.push(...itemIDs) : idList.push(itemIDs);

    const relatedKeyBookedItems = Array.from(relatedExtraBookedItems.keys()).filter(keyItem =>
      itemIDs.includes(keyItem.id)
    );
    if (relatedKeyBookedItems && relatedKeyBookedItems.length == 1) {
      const relatedItemsList = relatedExtraBookedItems.get(relatedKeyBookedItems[0]);
      if (relatedItemsList) {
        relatedItemsList.forEach(item => idList.push(item.id));
      }
    }

    seItemIDsToRemove(idList);
    if (isHotelType(idList)) {
      setShowRemovalModal(true);
    } else {
      setIsLoading(true);
      await executeItemRemoval(idList)
        .then(() => {
          if (bookingService.booking && bookingService.booking.bookedItems) {
            bookingItemRemovedEvent.broadcast({
              booking: bookingService.booking,
              items: bookingService.booking.bookedItems.filter(bookedItem =>
                itemIDs.includes(bookedItem.id)
              )
            });
          }
        })
        .catch(() => {
          onErrorAction && onErrorAction();
        })
        .finally(() => setIsLoading(false));
    }
    return Promise.resolve();
  }

  function editBookingItem(itemIDs: Array<string> | string): Promise<void> {
    const idList = [];
    Array.isArray(itemIDs) ? idList.push(...itemIDs) : idList.push(itemIDs);
    seItemIDsToEdit(idList);
    const itemID = Array.isArray(itemIDs) ? itemIDs[0] : itemIDs;
    const parentIds: string[] = [];
    if (bookingService.booking) {
      bookingService.booking.bookedItems.forEach((value: ApiBookedItem) => {
        if (itemIDs.includes(value.id)) {
          parentIds.push(value.idParent);
        }
      });
    }
    if (bookingService.booking && bookingService.booking.bookedItems) {
      bookingItemRemovedEvent.broadcast({
        booking: bookingService.booking,
        items: bookingService.booking.bookedItems.filter(bookedItem =>
          itemIDs.includes(bookedItem.id)
        )
      });
    }
    if (BookingUtils.isHotelType(booking, itemID)) {
      setShowEditModal(true);
    } else if (BookingUtils.isExtraType(booking, itemID)) {
      if (location.pathname === PageUrl.EXTRAS_PAGE || onExtrasListPage) {
        cartEditItemEvent.broadcast({
          component: null,
          itemId: onExtrasListPage && parentIds[0] ? parentIds[0] : itemID,
          itemType: ApiItemType.EXTRA
        });
      } else {
        history.replace(
          `${PageUrl.EXTRAS_PAGE}?${query.toString()}${
            isRebookingWorkflow ? '&rebookingWorkflow=true' : ''
          }`,
          { selectedItemId: itemID }
        );
      }
    } else if (BookingUtils.isInsuranceType(booking, itemID)) {
      cartEditItemEvent.broadcast({
        component: null,
        itemId: itemID,
        itemType: ApiItemType.INSURANCE
      });
    }
    if (editBookingItemExtension) {
      editBookingItemExtension(idList);
    }
    return Promise.resolve();
  }

  function isHotelType(itemIds: Array<string>): boolean {
    return (
      !!booking &&
      booking.bookedItems.filter(bookingItem => itemIds.includes(bookingItem.id)).pop()
        ?.itemType === ApiItemType.HOTEL
    );
  }

  function getSummaryPopoverActions(): SummaryPopoverActions {
    return {
      onItemDelete: removeItemFromBooking,
      onItemEdit: editBookingItem,
      onGoToCheckout
    };
  }

  function displayExtraServiceDate(item: ApiBookedItem) {
    if (isExtraWorkflow) {
      return true;
    }
    const itemComponent = CartUtil.getComponent(packageCartStore.cart, item.idParent);
    return (
      itemComponent?.selectionType === ApiServiceSelectionType.MULTIPLEDAYS ||
      itemComponent?.selectionType === ApiServiceSelectionType.CONSECUTIVEDAYS ||
      itemComponent?.selectionType === ApiServiceSelectionType.RADIOSELECTION ||
      itemComponent?.selectionType === ApiServiceSelectionType.TIMESLOT
    );
  }

  function displayExtraServiceTime(item: ApiBookedItem) {
    const itemComponent = CartUtil.getComponent(packageCartStore.cart, item.idParent);
    return itemComponent?.selectionType === ApiServiceSelectionType.TIMESLOT;
  }

  function displayRelatedItems(item: ApiBookedItem) {
    const itemComponent = CartUtil.getComponent(packageCartStore.cart, item.idParent);
    return (
      itemComponent?.selectionType === ApiServiceSelectionType.MULTIPLEDAYS ||
      itemComponent?.selectionType === ApiServiceSelectionType.CONSECUTIVEDAYS ||
      itemComponent?.selectionType === ApiServiceSelectionType.TIMESLOT
    );
  }

  return booking ? (
    <>
      {isLoggedIn && <span className="header__separator" />}
      <div id="shopping_basket" className="shopping_basket_container">
        <LoadingOverlay isLoading={isLoading}>
          <SummaryPopover
            mode={'default'}
            booking={booking}
            isMobile={!matches}
            ignoreOnMobile
            actions={getSummaryPopoverActions()}
            header={!isAddExtraWorkflow ? buildHeader() : undefined}
            hideEdit
            showDiscounts={booking.promoCodes.length > 0}
            showFees
            groupSameItems
            bookedItemsContainerClass={`iso__summary__container${
              getBookedItemsList(undefined, booking?.bookedItems, true).length > 4
                ? ' iso__summary__container--overflow'
                : ''
            }`}
            relatedItems={relatedExtraBookedItems}
            disableBorder
            displayExtraServiceDate={displayExtraServiceDate}
            displayExtraServiceTime={displayExtraServiceTime}
            displayRelatedItems={displayRelatedItems}
            showPaxInSummary
            useCustomItemOrder
            customItemRegistry={customItemRegistry}
            showOnlyPriceOfNewItems={!!isAddExtraWorkflow}
            isInEditMode={isInEditMode}
            isLoading={isLoading}
          />
          {isLoading && <Overlay />}
        </LoadingOverlay>
        <Modal
          className="summaryPopoverWithActions_removal-modal"
          isOpen={showRemovalModal}
          backdrop={true}
          toggle={() => setShowRemovalModal(!showRemovalModal)}
          container={document.getElementById('iso') || undefined}
        >
          <ModalHeader>{t(Keys.removalModal.header)}</ModalHeader>
          <ModalBody id={booking.id}>{t(Keys.removalModal.content)}</ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col xs={12} md={'auto'} className="mb-2">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={(): void => {
                    setIsLoading(true);
                    executeItemRemoval(itemIDsToRemove || [])
                      .then(() => {
                        const params = getSearchParams(PageUrl.HOTEL_LIST);
                        if (params) {
                          if (isRebookingWorkflow) {
                            history.push(
                              `${PageUrl.HOTEL_LIST}?${'wrk=' + params.wrk}${
                                isRebookingWorkflow ? '&rebookingWorkflow=true' : ''
                              }`
                            );
                          } else if (disablePackageNavigation) {
                            history.push(
                              `${PageUrl.HOTEL_LIST}${PageUtil.buildSingleHotelListParameters(
                                query,
                                packageModel?.code
                              )}`
                            );
                          } else if (onSingleHotelWorkflow) {
                            history.push(`${PageUrl.HOTEL_WORKFLOW}?${'wrk=' + params.wrk}`);
                          } else {
                            history.push(`${PageUrl.PACKAGE}?${'wrk=' + params.wrk}`);
                          }
                        }
                      })
                      .finally(() => {
                        setIsLoading(false);
                        setShowRemovalModal(false);
                      });
                  }}
                >
                  {t(Keys.removalModal.remove)}
                </button>
              </Col>
              <Col xs={12} md={'auto'} className="ml-2 mb-2">
                <button
                  type="button"
                  className="btn btn-secondary btn-block"
                  onClick={(): void => {
                    setShowRemovalModal(false);
                  }}
                >
                  {t(Keys.removalModal.cancel)}
                </button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal
          className="summaryPopoverWithActions_edit-modal"
          isOpen={showEditModal}
          backdrop={true}
          toggle={() => setShowEditModal(!showEditModal)}
          container={document.getElementById('iso') || undefined}
        >
          <ModalHeader>{t(Keys.editModal.header)}</ModalHeader>
          <ModalBody id={booking.id}>{t(Keys.editModal.content)}</ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col xs={12} md={'auto'} className="mb-2">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={(): void => {
                    setIsLoading(true);
                    executeItemRemoval(itemIDsToEdit || [])
                      .then(() => {
                        const params = getSearchParams(PageUrl.HOTEL_LIST);
                        if (params) {
                          if (isRebookingWorkflow) {
                            history.push(
                              `${PageUrl.HOTEL_LIST}?${'wrk=' + params.wrk}${
                                isRebookingWorkflow ? '&rebookingWorkflow=true' : ''
                              }`
                            );
                          } else if (disablePackageNavigation) {
                            history.push(
                              `${PageUrl.HOTEL_LIST}${PageUtil.buildSingleHotelListParameters(
                                query,
                                packageModel?.code
                              )}`
                            );
                          } else if (onSingleHotelWorkflow) {
                            history.push(`${PageUrl.HOTEL_WORKFLOW}?${'wrk=' + params.wrk}`);
                          } else {
                            history.push(`${PageUrl.PACKAGE}?${'wrk=' + params.wrk}`);
                          }
                        }
                      })
                      .finally(() => {
                        setIsLoading(false);
                        setShowEditModal(false);
                      });
                  }}
                >
                  {t(Keys.editModal.remove)}
                </button>
              </Col>
              <Col xs={12} md={'auto'} className="ml-2 mb-2">
                <button
                  type="button"
                  className="btn btn-secondary btn-block"
                  onClick={(): void => {
                    setShowEditModal(false);
                  }}
                >
                  {t(Keys.editModal.cancel)}
                </button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    </>
  ) : (
    <></>
  );
});

export default SummaryPopoverWithActions;
