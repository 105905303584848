import React, { useContext } from 'react';

import { ApiAdditionalInfoType, ApiBaseData, ApiHotel, ApiHotelRoom, ApiRoomRate } from '@ibe/api';
import { sanitize } from '@ibe/services';
import {
  BookedItemComponentProps,
  Dates,
  ServicesPillsDisplayOption,
  Price,
  ServicesPills,
  useConfig,
  useTranslation,
  packageCartStoreContext
} from '@ibe/components';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenAlt } from '@fortawesome/free-solid-svg-icons';
import fallback from '../../../Translations/generated/Items.en.json';
import Keys from '../../../Translations/generated/Items.en.json.keys';
import { PageUrl } from '../../../pages';
import { useHistory } from 'react-router';
import useQuery from '../../../Util/useQuery';
import { QueryUtils } from '../../../Util/QueryUtils';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { useLocalStore } from 'mobx-react';
import CartUtil from '../../../Util/CartUtil';

const CustomHotelCartView = (props: BookedItemComponentProps): JSX.Element => {
  const { item, booking, showPax, useCustomItemOrder, isInEditMode } = props;

  const { t } = useTranslation('Items', fallback);
  const history = useHistory();
  const config = useConfig();
  const query = useQuery();

  const packageCartContext = useContext(packageCartStoreContext);
  const packageCartStore = useLocalStore(() => packageCartContext);

  const characteristics: Array<ApiBaseData> = CartUtil.getCharacteristicsFromComponents(
    packageCartStore.cart
  );

  const price = item.price || null;

  const hotelsBookedItems = booking.bookedItems.filter(
    bi => bi.relatedBookedItemId === item.relatedBookedItemId && bi.itemType === item.itemType
  );
  const hotelsBookedItemsParentIds = hotelsBookedItems.map(hbi => hbi.idParent);
  const roomRates = booking.items.filter(it =>
    hotelsBookedItemsParentIds.includes(it.id)
  ) as ApiRoomRate[];
  const rooms = roomRates.map(rr =>
    booking.items.find(it => rr.idParent === it.id)
  ) as ApiHotelRoom[];
  const firstHotelItemId = rooms.map(r => r.idParent)[0]; // assuming all rooms are in one hotel
  const hotel = booking.items.find(it => firstHotelItemId === it.id) as ApiHotel;
  const nightCount = dayjs(item.endDate).diff(item.startDate, 'day');

  const resortFeeInformation = hotel?.additionalInfo?.find(
    v => v.infoType === ApiAdditionalInfoType.RESORTFEE
  )?.text;

  function onTravelDateEditClick() {
    redirectToHotelDetails();
  }

  function onHotelNameEditClick() {
    redirectToHotelList();
  }

  function onRoomNameEditClick() {
    redirectToHotelDetails();
  }

  function onPaxSelectionEditClick() {
    redirectToHotelDetails();
  }

  function redirectToHotelList() {
    const start = QueryUtils.getSearchStartDate(query);
    const end = QueryUtils.getSearchEndDate(query);
    const defaultNumberOfAdults = QueryUtils.getParsedDefaultNumberOfAdults(query);
    const maxAdults = QueryUtils.getParsedMaxAdults(query);
    const maxChildren = QueryUtils.getParsedMaxChildren(query);
    const maxChildAge = QueryUtils.getParsedMaxChildAge(query);
    const maxDuration = QueryUtils.getParsedMaxDuration(query);
    const workflowName = QueryUtils.getWorkflowCode(query);
    const code = QueryUtils.getPackageCode(query);
    const salesChannel = QueryUtils.getSalesChannelCode(query);
    const occupancy = QueryUtils.getOccupancy(query);

    history.push(
      `${PageUrl.HOTEL_LIST}${'?adults=' + defaultNumberOfAdults}${'&maxAdults=' + maxAdults}${
        '&start=' + start
      }${'&end=' + end}${'&maxChildren=' + maxChildren}${'&maxChildAge=' + maxChildAge}${
        '&maxDuration=' + maxDuration
      }${'&wrk=' + workflowName}${'&code=' + code}${'&salesChannelCode=' + salesChannel}${
        '&occupancy=' + occupancy
      }
      `,
      { noNavigationBackModal: true }
    );
  }

  function redirectToHotelDetails() {
    const occupancy = QueryUtils.getOccupancy(query);
    const selectedItemCode = QueryUtils.getItem(query);
    const workflowName = QueryUtils.getWorkflowCode(query);
    const code = QueryUtils.getPackageCode(query);
    const salesChannel = QueryUtils.getSalesChannelCode(query);

    const defaultNumberOfAdults = QueryUtils.getParsedDefaultNumberOfAdults(query);
    const maxAdults = QueryUtils.getParsedMaxAdults(query);
    const maxChildren = QueryUtils.getParsedMaxChildren(query);
    const maxChildAge = QueryUtils.getParsedMaxChildAge(query);
    const maxDuration = QueryUtils.getParsedMaxDuration(query);
    const start = QueryUtils.getSearchStartDate(query);
    const end = QueryUtils.getSearchEndDate(query);

    history.push(
      `${PageUrl.HOTEL_LIST}${'?occupancy=' + occupancy}${'&item=' + selectedItemCode}${
        '&wrk=' + workflowName
      }${'&code=' + code}${'&salesChannelCode=' + salesChannel}${
        '&adults=' + defaultNumberOfAdults
      }${'&maxAdults=' + maxAdults}${'&maxChildren=' + maxChildren}${
        '&maxChildAge=' + maxChildAge
      }${'&maxDuration=' + maxDuration}${'&start=' + start}${'&end=' + end}
      `,
      { noNavigationBackModal: true }
    );
  }

  const renderEdit = (): JSX.Element => (
    <>
      <Col xs="auto" className="cart__edit d-flex justify-content-end align-items-end pr-0 pb-2">
        <Button
          outline
          color="secondary btn-sm"
          onClick={(): void => {
            onHotelNameEditClick();
          }}
        >
          <FontAwesomeIcon icon={faPenAlt} />
        </Button>
      </Col>
    </>
  );

  const getDateFormat = () => {
    const defaultDateFormat =
      config.displayFormatDate[i18next.language || localStorage.getItem('i18nextLng') || ''] ||
      config.displayFormatDate[config.defaultLanguage];
    return `ddd ${defaultDateFormat}`;
  };

  return (
    <>
      <Row>
        <Col className="cart__row__container">
          <Row className="cart__row__date p-0">
            <Col className="d-flex py-0">
              <Row className="w-100 d-flex justify-content-center">
                <Col>
                  <Dates item={item} format={getDateFormat()} />
                </Col>
                <Col className="col-1 pb-2">
                  {isInEditMode && (
                    <Button
                      outline
                      color="secondary btn-sm"
                      onClick={(): void => {
                        onTravelDateEditClick();
                      }}
                    >
                      <FontAwesomeIcon icon={faPenAlt} />
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
            {!resortFeeInformation && !useCustomItemOrder && (
              <Col className="item-cart d-flex align-items-center justify-content-end ">
                <span className="text-muted ml-1">{price && <Price price={price} />}</span>
              </Col>
            )}
          </Row>
          <>
            {!!characteristics && characteristics.length > 0 && (
              <ServicesPills
                items={characteristics}
                textMode
                displayOption={ServicesPillsDisplayOption.PACKAGE}
              />
            )}
          </>
          <Row className="cart__row__name align-items-center no-gutters">
            <Col className="cart__name">
              <span>{`${t(Keys.nightsAt, { count: nightCount })} ${hotel.name}`}</span>
            </Col>
            {isInEditMode && (
              <div className="cart__buttons__container d-flex col-auto">{renderEdit()}</div>
            )}
          </Row>
          {rooms.map(r => {
            const roomRateId = r.roomrates[0]?.id;
            const bookedItem = hotelsBookedItems.find(hbi => hbi.idParent === roomRateId);
            const adults = bookedItem?.paxRequirement?.paxes?.adults ?? 0;
            const children = bookedItem?.paxRequirement?.paxes?.children ?? 0;
            const infants = bookedItem?.paxRequirement?.paxes?.infants ?? 0;
            const roomPrice = r.roomrates[0]?.totalPrice;
            const roomCharacteristics = CartUtil.getCharacteristicsForRoom(
              packageCartStore.cart,
              r,
              characteristics
            );
            return (
              <Row key={r.id} className="cart__row__room align-items-center">
                <Col className="pr-0 cart__row__room-name">
                  <div dangerouslySetInnerHTML={{ __html: sanitize(r.name) }} />
                </Col>
                <Col className="col-1 pl-0 pb-2">
                  {isInEditMode && (
                    <Button
                      outline
                      color="secondary btn-sm"
                      onClick={(): void => {
                        onRoomNameEditClick();
                      }}
                    >
                      <FontAwesomeIcon icon={faPenAlt} />
                    </Button>
                  )}
                </Col>
                {showPax && bookedItem && (
                  <Col xs={12}>
                    <Row className="w-100 cart__row__travelers d-flex align-items-center">
                      <Col className="pl-0">
                        {`${
                          adults > 0
                            ? `${adults} ${t(Keys.adult, {
                                count: adults
                              })}`
                            : ''
                        }${adults > 0 && (children > 0 || infants > 0) ? `, ` : ''}${
                          children > 0
                            ? `${children} ${t(Keys.child, {
                                count: children
                              })}`
                            : ''
                        }${children > 0 && infants > 0 ? `, ` : ''}${
                          infants > 0
                            ? `${infants} ${t(Keys.infant, {
                                count: infants
                              })}`
                            : ''
                        }`}
                      </Col>
                      <Col className="col-1 pb-2">
                        {isInEditMode && (
                          <Button
                            outline
                            className=""
                            color="secondary btn-sm"
                            onClick={(): void => {
                              onPaxSelectionEditClick();
                            }}
                          >
                            <FontAwesomeIcon icon={faPenAlt} />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col>
                  {!!characteristics && characteristics.length > 0 && (
                    <ServicesPills
                      items={[...characteristics]}
                      textMode
                      displayOption={ServicesPillsDisplayOption.HOTEL}
                    />
                  )}
                  {!!roomCharacteristics && roomCharacteristics.length > 0 && (
                    <ServicesPills
                      items={[...roomCharacteristics]}
                      textMode
                      displayOption={ServicesPillsDisplayOption.HOTEL}
                    />
                  )}
                </Col>
                {useCustomItemOrder && roomPrice && (
                  <Col xs={12}>
                    <Price className="text-muted" price={roomPrice} />
                  </Col>
                )}
              </Row>
            );
          })}
          {resortFeeInformation && (
            <Row className="cart__row__resort-fee">
              <Col className="d-flex align-items-center justify-content-between">
                <span className="font-weight-bold">{resortFeeInformation}</span>
                {!useCustomItemOrder && (
                  <span className="text-muted ml-1">{price && <Price price={price} />}</span>
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CustomHotelCartView;
