import styled from '@emotion/styled';

const MaxWidth = styled.div`
  padding: 2rem;
  max-width: 75rem;
  margin: 0 auto;
  position: relative;
`;

export default MaxWidth;
