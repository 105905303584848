import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import XHR from 'i18next-xhr-backend';

import { ConfigModel } from '@ibe/services';
import getExternalConfig from '../Config/externalConfigs';
import { ExternalParamsContextProps } from '../Config/ExternalParamsContext';
import { Language } from './index';

const order = ['widgetDOMDetector', 'querystring', 'localStorage', 'navigator'];

const widgetDOMDetector = {
  name: 'widgetDOMDetector',

  lookup(): string | undefined {
    return document.body.dataset.culture || document.body.dataset.locale; // the possible place where language will be defined in DOM (copied from test env)
  },

  cacheUserLanguage(lng: string) {
    localStorage.setItem('i18nextLng', lng);
  }
};

const customLanguageDetector = new LanguageDetector();
customLanguageDetector.addDetector(widgetDOMDetector);

const initI18next = async (externalParams?: ExternalParamsContextProps): Promise<void> => {
  let externalConfig: Partial<ConfigModel> = {};
  if (!!externalParams) {
    externalConfig = getExternalConfig(externalParams, externalConfig);
  }

  let version = '0';
  try {
    const rs = await fetch(
      `${externalConfig ? externalConfig.translationsUrl || '' : ''}/translations/generated/version`
    );
    version = await rs.text();
    console.debug('Translations version fetch ' + version);
  } catch (e) {
    console.debug('Translations version fetch failed.');
  }

  await i18next
    .use(initReactI18next)
    .use(customLanguageDetector)
    .use(Backend)
    .init({
      fallbackLng: Language.EN,
      debug: false,
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      react: {
        useSuspense: false
      },
      ns: [],
      backend: {
        backends: [XHR],
        allowMultiLoading: true,
        backendOptions: [
          {
            loadPath: `${
              externalConfig ? externalConfig.translationsUrl || '' : ''
            }/translations/generated/{{ns}}.{{lng}}.json`,
            queryStringParams: { v: version }
          }
        ]
      },
      detection: {
        order
      }
    });
};

export default initI18next;
