import {
  ApiBaseData,
  ApiBookedItem,
  ApiBooking,
  ApiBookingState,
  ApiComponent,
  ApiComponentType,
  ApiExtraService,
  ApiHotel,
  ApiHotelRoom,
  ApiItem,
  ApiItemType,
  ApiPackageCart,
  ApiPaxRequirement,
  ApiService,
  ApiServiceSelectionType,
  ApiTraveler
} from '@ibe/api';
import { clone, decodeObjectBase64 } from '@ibe/services';
import { ExtendedApiBookedItem, ItemKey } from '@ibe/components';
import dayjs from 'dayjs';
import BookingUtils from './BookingUtils';

class ServiceKey {
  serviceCode: string;

  startDate: number;

  endDate: number;
}

class CartUtil {
  public static findRelatedExtraBookedItems(
    packageCart: ApiPackageCart | null,
    booking: ApiBooking | null,
    isAddExtraWorkflow: boolean
  ): Map<ApiBookedItem, ApiBookedItem[]> {
    const relations: Map<ApiBookedItem, ApiBookedItem[]> = new Map();

    if (packageCart === null || booking === null) {
      return relations;
    }

    const bookingObj = clone(booking) as ApiBooking;

    const extraBookedItems: ApiBookedItem[] = bookingObj.bookedItems
      .filter(
        bookedItem =>
          bookedItem.componentType === ApiComponentType.OPTIONAL &&
          bookedItem.itemType === ApiItemType.EXTRA
      )
      .filter(bookedItem => {
        if (isAddExtraWorkflow) {
          return bookedItem.bookingState !== ApiBookingState.BOOKED;
        }
        return true;
      });

    const byServiceKey: Map<string, ApiBookedItem[]> = new Map();
    for (const bookedItem of extraBookedItems) {
      const itemId = bookedItem.idParent;
      const serviceKey = this.getServiceKey(itemId);
      const component = this.getComponent(packageCart, itemId);

      if (
        component?.selectionType === ApiServiceSelectionType.MULTIPLESERVICES ||
        component?.selectionType === ApiServiceSelectionType.SINGLESELECTION
      ) {
        continue;
      }

      if (serviceKey) {
        const byServiceKyMapping = byServiceKey.get(serviceKey.serviceCode);
        if (byServiceKyMapping) {
          byServiceKyMapping.push(bookedItem);
        } else {
          byServiceKey.set(serviceKey.serviceCode, [bookedItem]);
        }
      }
    }

    byServiceKey.forEach((value: ApiBookedItem[]) => {
      const key = value[0];
      if (key) {
        relations.set(key, value);
      }
    });

    const mergedRelations: Map<ApiBookedItem, ApiBookedItem[]> = new Map();
    const addedKeyItemsNames = new Map<string, ApiBookedItem[]>();
    for (const [key, val] of relations) {
      if (!addedKeyItemsNames.has(key.name)) {
        addedKeyItemsNames.set(key.name, val);
      } else {
        addedKeyItemsNames.get(key.name)?.push(...val);
      }
    }

    for (const [key] of relations) {
      mergedRelations.set(key, addedKeyItemsNames.get(key.name) || []);
    }

    return mergedRelations;
  }

  public static getServiceKey(itemId: string): ServiceKey | undefined {
    const bookedItemIdParentParent: string =
      decodeObjectBase64<ItemKey>(ItemKey, itemId).idParent || '';
    if (bookedItemIdParentParent) {
      const serviceKey = decodeObjectBase64<ServiceKey>(ServiceKey, bookedItemIdParentParent) || '';

      return serviceKey;
    }
    return undefined;
  }

  public static getComponent(
    packageCart: ApiPackageCart | null,
    itemId: string
  ): ApiComponent | undefined {
    const serviceKey = this.getServiceKey(itemId);

    if (serviceKey) {
      const component = packageCart?.packageModel.packageDetails?.[0].components.find(
        c => !!c.selectedItems.find(si => this.itemToServiceKeyPredicate(si, serviceKey))
      );

      return component;
    }

    return undefined;
  }

  public static getCharacteristicsFromComponents(packageCart: ApiPackageCart | null) {
    const components = packageCart?.packageModel?.packageDetails?.[0].components;
    const characteristics: ApiBaseData[] = [];
    if (components) {
      components
        .filter(
          component =>
            component.componentType === ApiComponentType.MAIN ||
            component.componentType === ApiComponentType.REQUIRED
        )
        .forEach(component => {
          component.selectedItems.forEach(si => {
            let selectedItem: ApiHotel | ApiExtraService | null = null;
            if (si.type === 'Hotel') {
              selectedItem = si as ApiHotel;
              characteristics.push(...selectedItem.characteristics);
            } else if (si.type === 'ExtraService') {
              selectedItem = si as ApiExtraService;
              characteristics.push(...selectedItem.characteristics);
            }
          });
        });
    }
    return characteristics;
  }

  public static getCharacteristicsForRoom(
    packageCart: ApiPackageCart | null,
    room: ApiHotelRoom,
    hotelCharacteristics: Array<ApiBaseData>
  ) {
    const components = packageCart?.packageModel?.packageDetails?.[0].components;
    const characteristics: ApiBaseData[] = [];
    if (components) {
      components.forEach(component => {
        component.selectedItems.forEach(si => {
          if (si.type === 'Hotel') {
            characteristics.push(
              ...(si as ApiHotel).rooms
                .filter(r => {
                  return (
                    r.roomCode === room.roomCode &&
                    r.roomrates?.[0]?.roomNumber === room.roomrates?.[0]?.roomNumber
                  );
                })
                .flatMap(s => s.characteristics)
            );
          }
        });
      });
    }

    return characteristics.filter(
      roomCh => !hotelCharacteristics.some(hotelCh => roomCh.code === hotelCh.code)
    );
  }

  public static getRemovableItems(
    packageCart: ApiPackageCart | null,
    itemIDs: string[]
  ): Map<ApiComponent, string[]> {
    const processedItems: Map<ApiComponent, string[]> = new Map();

    if (packageCart === null) {
      return processedItems;
    }

    for (const itemID of itemIDs) {
      const serviceKey = this.getServiceKey(itemID);
      const component = this.getComponent(packageCart, itemID);

      if (serviceKey && component) {
        const item = component.selectedItems.find(si =>
          this.itemToServiceKeyPredicate(si, serviceKey)
        );
        processedItems.set(component, [...(processedItems.get(component) || []), item?.id || '']);
      }
    }

    return processedItems;
  }

  private static itemToServiceKeyPredicate(si: ApiItem, sk: ServiceKey): boolean {
    return (
      (si as ApiService).code === sk.serviceCode &&
      (si as ApiService).start === dayjs(sk.startDate).toISOString().substring(0, 10) &&
      (si as ApiService).end === dayjs(sk.endDate).toISOString().substring(0, 10)
    );
  }

  public static getTicketComponent(components: ApiComponent[]): ApiComponent | undefined {
    return components.find(
      c =>
        c.componentType === ApiComponentType.REQUIRED &&
        c.itemType === ApiItemType.EXTRA &&
        c.displayDaily
    );
  }

  public static getSelectionType = (
    packageCart: ApiPackageCart | null,
    itemId: string
  ): ApiServiceSelectionType | undefined => {
    const component = this.getComponent(packageCart, itemId);
    return component?.selectionType;
  };

  public static getNumberOfAdults = (
    item: ExtendedApiBookedItem,
    relatedItems?: ApiBookedItem[]
  ) => {
    if (relatedItems && relatedItems.length > 0) {
      return relatedItems.map(value => value.paxRequirement?.paxes?.adults).reduce((a, b) => a + b);
    } else {
      return item.paxRequirement?.paxes?.adults ?? 0;
    }
  };

  public static getNumberOfChildren = (
    item: ExtendedApiBookedItem,
    relatedItems?: ApiBookedItem[]
  ) => {
    if (relatedItems && relatedItems.length > 0) {
      return relatedItems
        .map(value => value.paxRequirement?.paxes?.children)
        .reduce((a, b) => a + b);
    } else {
      return item.paxRequirement?.paxes?.children ?? 0;
    }
  };

  public static getNumberOfInfants = (
    item: ExtendedApiBookedItem,
    relatedItems?: ApiBookedItem[]
  ) => {
    if (relatedItems && relatedItems.length > 0) {
      return relatedItems
        .map(value => value.paxRequirement?.paxes?.infants)
        .reduce((a, b) => a + b);
    } else {
      return item.paxRequirement?.paxes?.infants ?? 0;
    }
  };

  public static getParticipantsPerRoom = (
    bookedPax: ApiPaxRequirement,
    booking: ApiBooking
  ): ApiTraveler[][] => {
    const bookedHotelBookedItems = BookingUtils.findBookedItemsByType(booking, ApiItemType.HOTEL);
    const bookedHotelsOccupancy =
      (bookedHotelBookedItems &&
        bookedHotelBookedItems.map(bookedHotelBookedItem =>
          BookingUtils.adaptRoomContainer(bookedHotelBookedItem.paxRequirement.paxes)
        )) ||
      [];

    const travelersPerRoom: ApiTraveler[][] = BookingUtils.getTravelersPerRoom(
      bookedHotelsOccupancy,
      booking.travelers
    );

    travelersPerRoom.forEach((room, idx) => {
      travelersPerRoom[idx] = room.filter(traveler =>
        bookedPax.paxes.travelersIds?.includes(traveler.id)
      );
    });

    return travelersPerRoom;
  };
}

export default CartUtil;
