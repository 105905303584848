/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-08-01 08:48:35 */

const Keys = {
  removalModal: {
    header: 'removalModal.header',
    content: 'removalModal.content',
    remove: 'removalModal.remove',
    cancel: 'removalModal.cancel'
  },
  editModal: {
    header: 'editModal.header',
    content: 'editModal.content',
    remove: 'editModal.remove',
    cancel: 'editModal.cancel'
  },
  included: 'included',
  edit: 'edit'
}; export default Keys;