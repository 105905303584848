import React, { createContext, PropsWithChildren, useContext } from 'react';
import { ServiceSearchParams } from '@ibe/components';

export type ExternalParamsContextProps = {
  mode: 'LOCAL' | 'TEST' | 'PROD' | 'INTTEST' | 'INTPROD';
  brand:
    | 'LLD'
    | 'LLC'
    | 'LLF'
    | 'LNY'
    | 'GLH'
    | 'HPR'
    | 'TPR'
    | 'ATR'
    | 'WAC'
    | 'CWA'
    | 'LLW'
    | 'LLB';
  widgetSessionKeyPrefix?: string;
};

export type ExternalSearchProps = {
  packageCode: string;
  hotelCode?: string;
  workflow: string;
  salesChannel: string;
  showHotelDetailsPopup: string;
  redirectUrl: string;
  paxDefinition?: {
    maxAdults?: number;
    maxChildren?: number;
    maxChildAge?: number;
    defaultAdults?: number;
    maxDuration?: number;
  };
  searchParams?: Partial<ServiceSearchParams>;
  triggerSearch?: boolean;
  stickyElementsOffset?: {
    mobile: {
      offset: number;
      breakpoint: number;
    };
    tablet: {
      offset: number;
      breakpoint: number;
    };
    desktop: {
      offset: number;
      breakpoint: number;
    };
  };
};

export const ExternalParamsContext = createContext<ExternalParamsContextProps | null>(null);

export const useExternalSearchParams = (): ExternalParamsContextProps | null => {
  return useContext<ExternalParamsContextProps | null>(ExternalParamsContext);
};

const ExternalParams = (props: PropsWithChildren<ExternalParamsContextProps>): JSX.Element => {
  const { children, ...rest } = props;

  return <ExternalParamsContext.Provider value={rest}>{children}</ExternalParamsContext.Provider>;
};

export default ExternalParams;
