import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
/* eslint import/no-cycle: "off" */
import BlankPageLayout from './BlankPageLayout';
import MaxWidth from './Helper/MaxWidth';

interface Props {
  children: React.ReactNode;
  title?: string;
  headline?: string;
  maxWidth?: string;
  padding?: string;
  className?: string;
}

const Box = styled.div``;

const PageLayout = observer((props: Props) => {
  const BackgroundContainer = styled.div``;

  return (
    <BlankPageLayout title={props.title} className={props.className}>
      <BackgroundContainer>
        <MaxWidth
          style={{ maxWidth: props.maxWidth, padding: props.padding }}
          className={'maxWidth-container'}
        >
          <Box className="px-0 backgroundContainer-box">{props.children}</Box>
        </MaxWidth>
      </BackgroundContainer>
    </BlankPageLayout>
  );
});

export default PageLayout;
