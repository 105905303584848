import React, { useContext } from 'react';

import { sanitize } from '@ibe/services';
import {
  BookedItemComponentProps,
  Dates,
  LoadingOverlay,
  packageCartStoreContext,
  Price,
  useBookingService,
  useTranslation
} from '@ibe/components';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  ApiBookedItem,
  ApiExtraService,
  ApiExtraUnit,
  ApiItem,
  ApiServiceSelectionType,
  ApiTravelerType,
  ApiUnitRate
} from '@ibe/api';
import fallback from '../../../Translations/generated/Items.en.json';
import Keys from '../../../Translations/generated/Items.en.json.keys';
import CartUtil from '../../../Util/CartUtil';
import { useLocalStore } from 'mobx-react';

function findItems(
  bookedItem: ApiBookedItem,
  items: ApiItem[]
): { extraService: ApiExtraService; extraUnit: ApiExtraUnit; extraUnitRate: ApiUnitRate } {
  const extraUnitRate = items.find(it => bookedItem.idParent === it.id) as ApiUnitRate;
  const extraUnit = items.find(it => extraUnitRate.idParent === it.id) as ApiExtraUnit;
  const extraService = items.find(it => extraUnit.idParent === it.id) as ApiExtraService;
  return { extraService, extraUnit, extraUnitRate };
}

const Cart = (props: BookedItemComponentProps): JSX.Element => {
  const {
    booking,
    item,
    relatedItems,
    onDelete,
    displayExtraServiceDate,
    displayExtraServiceTime,
    displayRelatedItems
  } = props;

  const { t } = useTranslation('Items', fallback);
  const packageCartContext = useContext(packageCartStoreContext);
  const packageCartStore = useLocalStore(() => packageCartContext);
  const bookingService = useBookingService();

  const selectionType = CartUtil.getSelectionType(packageCartStore.cart, item.idParent);
  const adults =
    selectionType === ApiServiceSelectionType.MULTIPLEDAYS ||
    selectionType === ApiServiceSelectionType.CONSECUTIVEDAYS
      ? CartUtil.getNumberOfAdults(item)
      : CartUtil.getNumberOfAdults(item, relatedItems);
  const children =
    selectionType === ApiServiceSelectionType.MULTIPLEDAYS ||
    selectionType === ApiServiceSelectionType.CONSECUTIVEDAYS
      ? CartUtil.getNumberOfChildren(item)
      : CartUtil.getNumberOfChildren(item, relatedItems);
  const infants =
    selectionType === ApiServiceSelectionType.MULTIPLEDAYS ||
    selectionType === ApiServiceSelectionType.CONSECUTIVEDAYS
      ? CartUtil.getNumberOfInfants(item)
      : CartUtil.getNumberOfInfants(item, relatedItems);

  const price = item.price || null;
  const description = item.description || null;

  const { extraUnitRate, extraUnit, extraService } = findItems(item, booking.items);

  const showPaxes = (): boolean => {
    return (
      selectionType === ApiServiceSelectionType.CONSECUTIVEDAYS ||
      selectionType === ApiServiceSelectionType.MULTIPLEDAYS ||
      selectionType === ApiServiceSelectionType.MULTIPLEPAX
    );
  };

  const getPaxesSummedPerRoomLabel = (relatedItem: ApiBookedItem): string[] => {
    const labels: string[] = [];
    const participantsPerRoom = CartUtil.getParticipantsPerRoom(
      relatedItem.paxRequirement,
      bookingService.booking || booking
    );
    participantsPerRoom.forEach((participants, idx) => {
      const roomLabel = participantsPerRoom.length > 1 ? idx + 1 + '. ' + t(Keys.room) + ': ' : '';
      const adultsSum = participants.filter(
        participant => participant.type === ApiTravelerType.ADULT
      ).length;
      const childrenSum = participants.filter(
        participant => participant.type === ApiTravelerType.CHILD
      ).length;
      const infantsSum = participants.filter(
        participant => participant.type === ApiTravelerType.INFANT
      ).length;
      if (adultsSum + childrenSum + infantsSum > 0) {
        labels.push(roomLabel + getParticipantsSummedLabel(adultsSum, childrenSum, infantsSum));
      }
    });
    return labels;
  };

  const getParticipantsSummedLabel = (
    adultsSum: number,
    childrenSum: number,
    infantsSum: number
  ) => {
    return `${
      adultsSum > 0
        ? `${adultsSum} ${t(Keys.adult, {
            count: adultsSum
          })}`
        : ''
    }${adultsSum > 0 && (childrenSum > 0 || infantsSum > 0) ? `, ` : ''}${
      childrenSum > 0
        ? `${childrenSum} ${t(Keys.child, {
            count: childrenSum
          })}`
        : ''
    }${childrenSum > 0 && infantsSum > 0 ? `, ` : ''}${
      infantsSum > 0
        ? `${infantsSum} ${t(Keys.infant, {
            count: infantsSum
          })}`
        : ''
    }`;
  };

  return (
    <LoadingOverlay>
      <Row>
        <Col>
          <h6 className="my-0">{item.name}</h6>
          {extraService.description && (
            <div dangerouslySetInnerHTML={{ __html: sanitize(extraService.description) }} />
          )}
        </Col>
        <Col xs={'auto'} className="d-flex justify-content-end align-items-end pl-0">
          {onDelete && (
            <Button outline color="primary btn-sm" onClick={() => onDelete(item.id)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        {showPaxes() && <Col xs={12}>{getParticipantsSummedLabel(adults, children, infants)}</Col>}
        {relatedItems && relatedItems.length > 0 ? (
          <>
            {description && (
              <Col xs={'12'}>
                <div
                  className={'iso__extra-cart__description'}
                  dangerouslySetInnerHTML={{ __html: sanitize(description) }}
                />
              </Col>
            )}
            {!displayRelatedItems ||
              (!displayRelatedItems(item) && (
                <Col xs={'12'}>
                  <Row>
                    <Col
                      className={`d-flex align-items-center ${
                        displayExtraServiceDate && !displayExtraServiceDate(item)
                          ? 'justify-content-end'
                          : 'justify-content-between'
                      }`}
                    >
                      {(!displayExtraServiceDate ||
                        (displayExtraServiceDate && displayExtraServiceDate(item))) && (
                        <Dates item={item} />
                      )}
                      <span className="text-muted ml-1">{price && <Price price={price} />}</span>
                    </Col>
                  </Row>
                </Col>
              ))}
            {(!displayRelatedItems || (displayRelatedItems && displayRelatedItems(item))) && (
              <Col xs={'12'}>
                {relatedItems.map(relatedItem => {
                  return (
                    <>
                      <Row className={'mt-2'}>
                        <Col className="d-flex align-items-center justify-content-between">
                          <Dates item={relatedItem} />
                          {displayExtraServiceTime && displayExtraServiceTime(item) && (
                            <span>{`${t(Keys.time)}: ${
                              findItems(relatedItem, booking.items).extraUnitRate.startTime
                            }`}</span>
                          )}
                          <span className="text-muted ml-1">
                            {relatedItem.price && <Price price={relatedItem.price} />}
                          </span>
                        </Col>
                      </Row>
                      {selectionType === ApiServiceSelectionType.TIMESLOT ? (
                        <Row>
                          <Col>
                            <>
                              {getPaxesSummedPerRoomLabel(relatedItem).map(roomLabel => {
                                return <div>{roomLabel}</div>;
                              })}
                            </>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </Col>
            )}
          </>
        ) : (
          <>
            <Col>
              {(!displayExtraServiceDate ||
                (displayExtraServiceDate && displayExtraServiceDate(item))) && (
                <Dates item={item} />
              )}
              {displayExtraServiceTime && displayExtraServiceTime(item) && (
                <span>{`${t(Keys.time)}: ${extraUnitRate.startTime}`}</span>
              )}
              {description && (
                <div
                  className={'iso__extra-cart__description'}
                  dangerouslySetInnerHTML={{ __html: sanitize(description) }}
                />
              )}
            </Col>
            <Col>
              <span className="text-muted ml-1">{price && <Price price={price} />}</span>
            </Col>
          </>
        )}
      </Row>
    </LoadingOverlay>
  );
};

export default Cart;
